import React, { useState, useEffect, useRef } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from 'react-router-dom';
import { makeRequest } from "../../makeRequest";
import { useSelector } from "react-redux";
import './cart-summary.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const CartSummary = ({ item }) => {
  const [isVisible, setIsVisible] = useState(false); // Initially hidden
  const cartSummaryRef = useRef(null);
  const products = useSelector((state) => state.cart.products);
  const navigate = useNavigate();

  // Show the cart summary whenever a new item is passed
  useEffect(() => {
    if (item) {
      setIsVisible(true);
    }
  }, [item]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cartSummaryRef.current && !cartSummaryRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const stripePromise = loadStripe(
    "pk_live_51NaTvbBriBbxyOm86cYCoK062FQ19FvKzGIJMJWHoQMrBkslADrh5p67uPKeBdbw8v4MAdYqo4DgXuR25Qcyk5q800HacqDqLK"
  );

  const handlePayment = async () => {
    try {
      const stripe = await stripePromise;
      const res = await makeRequest.post("/orders", { products });
      const { error } = await stripe.redirectToCheckout({ sessionId: res.data.stripeSession.id });
      if (error) {
        console.error(error);
        alert('Failed to initiate payment. Please try again.');
      }
    } catch (err) {
      console.error(err);
      alert('Failed to initiate payment. Please try again.');
    }
  };

  const handleProceedToCart = () => {
    setIsVisible(false);
    navigate('/cart');
  };

  const handleClose = () => {
    setIsVisible(false); // Immediate state update to hide the component
  };

  if (!isVisible) return null;

  return (
    <div className="cart-summary" ref={cartSummaryRef}>
      <div className="header">
        <FontAwesomeIcon icon={faCheck} className="check-icon" />
        <p>Item Added to Cart</p>
        <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={handleClose} />
      </div>

      <div className="item">
      <a href={`/products/${item.product_name}`} rel="noopener noreferrer">
        <img src={process.env.REACT_APP_UPLOAD_URL + item.img} alt="" />
      </a>
        <Link to={`/products/${item.product_name}`} className="product-link">
          <span>{item.title}</span>
          <p className="quantity">Qty: {item.quantity}</p>
        </Link>
        
      </div>


      <div className="buttons">
        <button className="cart" onClick={handleProceedToCart}>PROCEED TO CART</button>
        <button className="checkout" onClick={handlePayment}>PROCEED TO CHECKOUT</button>
      </div>
    </div>
  );
};

export default CartSummary;
