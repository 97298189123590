import React, { useState, useEffect } from "react";
import "./list.scss";
import ProductCard from "../productCard/productCard";
import useFetch from "../../hooks/useFetch";
import CartSummary from "../../components/cart-summary/cart-summary"; // Import CartSummary

const List = ({ brands, maxPrice, sort, title, condition }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(6);
  const [lastAddedItem, setLastAddedItem] = useState(null); // Track last added item for CartSummary
  const [showCartSummary, setShowCartSummary] = useState(false); // Control CartSummary visibility

  // Construct query parameters
  const brandFilters = brands.length > 0
    ? brands.map((item) => `&[filters][brands][id][$eq]=${item}`).join("")
    : "";
  const conditionFilter = condition
    ? `&[filters][condition][$eq]=${condition}`
    : "";
    
  const sortParam = sort && sort !== "default" ? `&sort=price:${sort}` : ""; // Only apply sort if it's not "default"

  
  const url = `/products?populate=*&[filters][sub_categories][title][$eq]=${title}${brandFilters}&[filters][price][$lte]=${maxPrice}${conditionFilter}${sortParam}&pagination[page]=${currentPage}&pagination[pageSize]=${pageSize}`;
  
  const { data, loading, error } = useFetch(url);

  // Reset to the first page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [brands, maxPrice, sort, condition]);

  const handleAddToCart = (product) => {
    setLastAddedItem(product); // Update the last added product
    setShowCartSummary(false); // Hide CartSummary first
    setTimeout(() => setShowCartSummary(true), 0); // Show CartSummary after a short delay
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) return <div className="loading-spinner">Loading...</div>;
  if (error) return <div className="error-message">There was an error fetching products. Please try again later.</div>;

  return (
    <div className="list">
      <div className="product-container">
        {data?.length > 0 ? (
          data.map((item) => (
            <ProductCard 
              item={item} 
              key={item.id} 
              onAddToCart={handleAddToCart} // Pass the handleAddToCart function to ProductCard
            />
          ))
        ) : (
          <div className="no-items">No items found based on your filters.</div>
        )}
      </div>

      {/* Conditionally Render CartSummary */}
      {showCartSummary && lastAddedItem && (
        <CartSummary item={lastAddedItem} onClose={() => setShowCartSummary(false)} />
      )}

      <div className="pagination-controls">
        <button 
          onClick={() => handlePageChange(currentPage - 1)} 
          disabled={currentPage <= 1}
        >
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button 
          onClick={() => handlePageChange(currentPage + 1)} 
          disabled={data?.length < pageSize}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default List;
