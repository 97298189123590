import React, { useState } from "react";
import Card from "../productCard/productCard"; // Assuming this is the correct import for your product card
import "./featuredProducts.scss";
import CartSummary from '../../components/cart-summary/cart-summary';
import useFetch from "../../hooks/useFetch";

const FeaturedProducts = ({ type }) => {
  const { data, loading, error } = useFetch(
    `/products?populate=*&[filters][type][$eq]=${type}`
  );

  const [lastAddedItem, setLastAddedItem] = useState(null); // Track the last added item
  const [showCartSummary, setShowCartSummary] = useState(false); // Control visibility of CartSummary

  const handleAddToCart = (product) => {
    // Triggered when an item is added to the cart
    setLastAddedItem(product);
    setShowCartSummary(true); // Show CartSummary when an item is added
    setTimeout(() => setShowCartSummary(false), 2000); // Hide CartSummary after 2 seconds
  };

  return (
    <div className="featuredProducts">
      <div className="top">
        <h3>{type} Products</h3>
      </div>
      <div className="bottom">
        {error ? (
          "Something went wrong!"
        ) : loading ? (
          "Loading..."
        ) : (
          <div className="product-list">
            {data &&
              data.map((item) => (
                <div key={item.id} className="product-item">
                  <Card
                    className="card-contain"
                    item={item}
                    onAddToCart={handleAddToCart} // Pass the handler down to the Card
                  />
                </div>
              ))}
          </div>
        )}
      </div>

      {/* Conditionally render CartSummary */}
      {showCartSummary && lastAddedItem && (
        <CartSummary
          item={lastAddedItem}
          onClose={() => setShowCartSummary(false)} // Optional close handler for CartSummary
        />
      )}
    </div>
  );
};

export default FeaturedProducts;
