import React, { useState } from 'react';
import './productCard.scss';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'; // Import dispatch from Redux
import { addToCart } from '../../redux/cartReducer'; // Import the action
import CartSummary from '../../components/cart-summary/cart-summary';

const ProductCard = ({ item }) => {
  const [lastAddedItem, setLastAddedItem] = useState(null); // Track the last added item
  const [showCartSummary, setShowCartSummary] = useState(false); // Control visibility of CartSummary

  const dispatch = useDispatch(); // Initialize dispatch

  const productName = item?.attributes.product_name;

  // Function to convert product name to URL-friendly format
  const formatProductNameToUrl = (productName) => {
    if (!productName) return ""; // Check if productName is undefined or null
    return productName.toLowerCase().replace(/\s+/g, '-');
  };

  const handleAddToCart = () => {
    const product = {
      id: item.id,
      title: item.attributes.title,
      price: item.attributes.price,
      img: item.attributes.img?.data?.attributes?.url,
      product_name: item.attributes.product_name,
      quantity: 1, // Default quantity for now
    };

    // Dispatch the addToCart action to Redux
    dispatch(addToCart(product)); // Add the product to the Redux store

    // Update lastAddedItem state
    setLastAddedItem(product);

    // Show the CartSummary
    setShowCartSummary(true);
  };

  const stockStatus = item?.attributes.status; // Get the stock status

  // Determine the color based on stock status
  const stockStatusColor = stockStatus === 'in stock' ? 'green' : 'red';

  console.log(item);

  return (
    <div className='card'>
      <Link className='link' to={`/products/${formatProductNameToUrl(productName)}`} style={{ textDecoration: 'none' }}>
        <a href="/product" className="card-link">
          <div className='image'>
            <img
              src={process.env.REACT_APP_UPLOAD_URL + item.attributes?.img?.data?.attributes?.url}
              alt={item.attributes.title || 'Product'}
              className='mainImg'
            />
          </div>
        </a>
        <p className='title'>{item?.attributes.title.substring(0, 50)}</p>
      </Link>
      <p className='sn'>SKU: {item?.attributes.sku}</p>
      <p className='price'>${item?.attributes.price.toFixed(2)}</p>
      <p className='condition'>{item?.attributes.condition}</p>
      <p className='status' style={{ color: stockStatusColor }}>
        {stockStatus === 'in stock' ? 'In Stock' : 'Out of Stock'}
      </p>
      <button
        className='add-to-cart'
        onClick={handleAddToCart}
        disabled={stockStatus !== 'in stock'} // Disable button if out of stock
        style={{
          cursor: stockStatus === 'in stock' ? 'pointer' : 'not-allowed',
          opacity: stockStatus === 'in stock' ? 1 : 0.6,
        }}
      >
        Add to Cart
      </button>

      {/* Conditionally Render CartSummary */}
      {showCartSummary && lastAddedItem && (
        <CartSummary
          item={lastAddedItem}
          onClose={() => setShowCartSummary(false)} // Optional close handler
        />
      )}
    </div>
  );
};

export default ProductCard;
