import React, { useState, useCallback, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import List from "../../components/categories-list/list";
import useFetch from "../../hooks/useFetch";
import "./categories.scss";

const Collection1 = () => {
  const { title } = useParams();
  const [maxPrice, setMaxPrice] = useState(10000);
  const [sort, setSort] = useState("default");
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState(null);

  // Fetch brands data
  const { data: brandsData, loading: brandsLoading } = useFetch(`/brands`);

  const handleBrandChange = useCallback((e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelectedBrands((prevBrands) =>
      isChecked ? [...prevBrands, value] : prevBrands.filter((item) => item !== value)
    );
  }, []);

  const handleSortChange = useCallback((e) => {
    setSort(e.target.value);
  }, []);

  const priceRange = useMemo(() => {
    return (
      <div className="inputItem">
        <span>0</span>
        <input
          type="range"
          min={0}
          max={10000}
          step={100}
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
        />
        <span>{maxPrice}</span>
      </div>
    );
  }, [maxPrice]);

  return (
    <div className="collection">
      <div className="title-container">
        <h1 className="title">{title}</h1>
      </div>

      <div className="breadcrumbs">
        <Link className="text1" to="/">home</Link> / &nbsp;{title}
      </div>

      <div className="products">
        <div className="left">
          {/* Filter by Brand */}
          <div className="filterItem">
            <h2>Brand</h2>
            {brandsLoading ? (
              <p>Loading brands...</p>
            ) : (
              brandsData?.map((item) => (
                <div className="inputItem" key={item.id}>
                  <input
                    type="checkbox"
                    id={`brand-${item.id}`}
                    value={item.id}
                    onChange={handleBrandChange}
                  />
                  <label htmlFor={`brand-${item.id}`}>
                    <p>{item.attributes.title}</p>
                  </label>
                </div>
              ))
            )}
          </div>

          {/* Filter by Price */}
          <div className="filterItem">
            <h2>Price</h2>
            {priceRange}
          </div>

          {/* Filter by Condition */}
          <div className="filterItem">
            <h2>Condition</h2>
            {/* Reset option */}
            <div className="inputItem">
              <input
                type="radio"
                id="all"
                name="condition"
                value=""
                onChange={() => setSelectedCondition(null)} // Reset condition
                checked={selectedCondition === null}
              />
              <label htmlFor="all">All</label>
            </div>

            {/* New condition */}
            <div className="inputItem">
              <input
                type="radio"
                id="New"
                name="condition"
                value="New"
                onChange={() => setSelectedCondition("New")}
                checked={selectedCondition === "New"}
              />
              <label htmlFor="New">New</label>
            </div>

            {/* Used condition */}
            <div className="inputItem">
              <input
                type="radio"
                id="Used"
                name="condition"
                value="Used"
                onChange={() => setSelectedCondition("Used")}
                checked={selectedCondition === "Used"}
              />
              <label htmlFor="Used">Used</label>
            </div>
          </div>

          {/* Sort Options */}
          <div className="filterItem">
            <h2>Sort By</h2>
            <select value={sort} onChange={handleSortChange}>
              <option value="default">Default</option>
              <option value="asc">Price: Low to High</option>
              <option value="desc">Price: High to Low</option>
            </select>
          </div>
        </div>

        <div className="right">
          {/* Product List */}
          <List
            title={title}
            maxPrice={maxPrice}
            sort={sort}
            brands={selectedBrands}
            condition={selectedCondition}
          />
        </div>
      </div>
    </div>
  );
};

export default Collection1;
