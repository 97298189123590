import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import List from "../../components/sub_categories-list/list";
import useFetch from "../../hooks/useFetch";
import "./sub_categories.scss";

const Collection2 = () => {
  const { title } = useParams();
  const [maxPrice, setMaxPrice] = useState(10000);
  const [sort, setSort] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState(""); // New state for condition

  // Fetch brands data
  const { data: brandsData } = useFetch(`/brands`);

  // Fetch sub-category and category data
  const { data, loading, error } = useFetch(
    `/sub-categories?filters[title][$eq]=${title}&populate=categories`
  );

  console.log("Sub-categories data:", data);

  const handleBrandChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelectedBrands(
      isChecked
        ? [...selectedBrands, value]
        : selectedBrands.filter((item) => item !== value)
    );
  };

  const handleSortChange = (e) => {
    setSort(e.target.value);
  };


  // Safely access category title from fetched data
  const categoryTitle =
    data?.[0]?.attributes.categories?.data?.[0]?.attributes?.title || "category";

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching data: {error.message}</p>;

  return (
    <div className="collection">
      <div className="title-container">
        <h1 className="title">{title}</h1>
      </div>
      <div className="breadcrumbs">
        <Link className="text1" to="/">home</Link> /&nbsp;
        <Link className="text2" to={`/categories/${categoryTitle}`}>{categoryTitle}</Link> /
        &nbsp;{title}
      </div>
      <div className="products">
        <div className="left">
          {/* Brand Filter */}
          <div className="filterItem">
            <h2>Brand</h2>
            {brandsData && brandsData.map((item) => (
              <div className="inputItem" key={item.id}>
                <input
                  type="checkbox"
                  id={item.id}
                  value={item.id}
                  onChange={handleBrandChange}
                />
                <label htmlFor={item.id}><p>{item.attributes.title}</p></label>
              </div>
            ))}
          </div>

          {/* Price Filter */}
          <div className="filterItem">
            <h2>Price</h2>
            <div className="inputItem">
              <span>0</span>
              <input
                type="range"
                min={0}
                max={10000}
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
              />
              <span>{maxPrice}</span>
            </div>
          </div>

           {/* Filter by Condition */}
           <div className="filterItem">
            <h2>Condition</h2>
            {/* Reset option */}
            <div className="inputItem">
              <input
                type="radio"
                id="all"
                name="condition"
                value=""
                onChange={() => setSelectedCondition(null)} // Reset condition
                checked={selectedCondition === null}
              />
              <label htmlFor="all">All</label>
            </div>

            {/* New condition */}
            <div className="inputItem">
              <input
                type="radio"
                id="New"
                name="condition"
                value="New"
                onChange={(e) => setSelectedCondition(e.target.value)}
                checked={selectedCondition === "New"}
              />
              <label htmlFor="New">New</label>
            </div>

            {/* Used condition */}
            <div className="inputItem">
              <input
                type="radio"
                id="Used"
                name="condition"
                value="Used"
                onChange={(e) => setSelectedCondition(e.target.value)}
                checked={selectedCondition === "Used"}
              />
              <label htmlFor="Used">Used</label>
            </div>
          </div>

         {/* Sort Options */}
         <div className="filterItem">
            <h2>Sort By</h2>
            <select value={sort} onChange={handleSortChange}>
              <option value="default">Default</option>
              <option value="asc">Price: Low to High</option>
              <option value="desc">Price: High to Low</option>
            </select>
          </div>
        </div>

        {/* Product List */}
        <div className="right">
          <List
            title={title}
            maxPrice={maxPrice}
            sort={sort}
            brands={selectedBrands}
            condition={selectedCondition} // Pass condition as a prop
          />
        </div>
      </div>
    </div>
  );
};

export default Collection2;
