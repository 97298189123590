import React, { useState } from "react";
import "./product.scss";
import useFetch from "../../hooks/useFetch";
import { FaShoppingCart } from "react-icons/fa";
import { useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartReducer";
import CartSummary from "../../components/cart-summary/cart-summary";

const Product = () => {
  const { product_name } = useParams();
  const [selectedImg, setSelectedImg] = useState("img");
  const [quantity, setQuantity] = useState(1);
  const [lastAddedItem, setLastAddedItem] = useState(null); // Track the last added item
  const [showCartSummary, setShowCartSummary] = useState(false); // Track visibility of CartSummary

  const dispatch = useDispatch();
  const { data, loading } = useFetch(`/products/${product_name}?populate=*`);
  const { data: productCat } = useFetch(`/products?filters[product_name][$eq]=${product_name}&populate=*`);
  const categoryTitle = productCat?.[0]?.attributes.categories?.data?.[0]?.attributes?.title || "category";
  const subcategoryTitle = productCat?.[0]?.attributes.sub_categories?.data?.[0]?.attributes?.title || "sub_category";
  console.log(data);
  const handleAddToCart = () => {
    if (isOutOfStock) {
      alert("Item cannot be added to cart because it is out of stock.");
    } else {
      const item = {
        id: data.id,
        title: data.attributes.title,
        desc: data.attributes.desc,
        price: data.attributes.price,
        img: data.attributes.img.data.attributes.url,
        product_name: data.attributes.product_name,
        quantity,
      

      };

     // Add the product to the cart using Redux
         dispatch(addToCart(item));


       
         // Update local state
         setLastAddedItem(item);
       
         // Reset showCartSummary to retrigger its appearance
         setShowCartSummary(false); // Hide CartSummary first
         setTimeout(() => setShowCartSummary(true), 0); // Show CartSummary again after a short delay
    }
  };

  const isOutOfStock = data?.attributes?.status?.toLowerCase() === "out of stock";

  const stockStatus = data?.attributes.status; 

  return (
    <div className="product-wrapper">
      {/* Conditionally render CartSummary */}
      {showCartSummary && lastAddedItem && <CartSummary item={lastAddedItem} />}
      
      {loading ? (
        "Stand By"
      ) : (
        <div className="breadcrumbs">
          <Link className="text1" to="/">home</Link> /
          <Link className="text2" to={`/categories/${categoryTitle}`}>{categoryTitle}</Link> /
          <Link className="text3" to={`/sub_categories/${subcategoryTitle}`}>{subcategoryTitle}</Link> /
          &nbsp;{product_name}
        </div>
      )}
      
      {loading ? (
        "Loading..."
      ) : (
        <div className="product">
          <div className="left">
          <div className="images">
              <img
                src={
                  (data?.attributes?.img?.data?.attributes?.url &&
                    process.env.REACT_APP_UPLOAD_URL +
                      data.attributes.img.data.attributes.url) ||
                  "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"
                }
                alt=""
                onClick={() => setSelectedImg("img")}
              />
              {data?.attributes?.img2?.data?.attributes?.url && (
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data.attributes.img2.data.attributes.url
                  }
                  alt=""
                  onClick={() => setSelectedImg("img2")}
                />
              )}
            </div>
            <div className="mainImg">
              <img
                src={
                  (selectedImg && data?.attributes[selectedImg]?.data?.attributes?.url &&
                    process.env.REACT_APP_UPLOAD_URL +
                      data.attributes[selectedImg].data.attributes.url) ||
                  "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"
                }
                alt=""
              />
            </div>
          </div>

          <div className="right">
            <p className="sku"><span>SKU:</span> {data?.attributes?.sku}</p>
            <h1 className="title">{data?.attributes?.title}</h1>
            <div className="product-details">
              <span className={`status ${isOutOfStock ? "out-of-stock" : "in-stock"}`}>
                {isOutOfStock ? (
                  <span className="status-text">Out of Stock: <span className="status-icon1">&#10005;</span></span>
                ) : (
                  <span className="status-text">In Stock: <span className="status-icon2">&#10003;</span></span>
                )}
              </span>
              <span className="condition"> <span>Condition:</span> {data?.attributes?.condition}</span>
            </div>
            <span className="price">${data?.attributes?.price.toFixed(2)}</span>
            <div className="quantity">
              <button className='minus' onClick={() => setQuantity((prev) => (prev === 1 ? 1 : prev - 1))}>-</button>
              {quantity}
              <button  className='plus' onClick={() => setQuantity((prev) => prev + 1)}>+</button>
            </div>
            {isOutOfStock && (
              <p className="out-of-stock-message" style={{ color: "red", fontSize: "1.2em" }}>
                This item is currently out of stock.
              </p>
            )}
            <button
              className='add'
              onClick={handleAddToCart}
              disabled={stockStatus !== 'in stock'} // Disable button if out of stock
              style={{
                cursor: stockStatus === 'in stock' ? 'pointer' : 'not-allowed',
                opacity: stockStatus === 'in stock' ? 1 : 0.6,
              }}
            >
              Add to Cart
            </button>
            <hr />
            <div className="info">
              <span>DESCRIPTION</span>
              <p>{data?.attributes?.desc}</p>
              <hr />
              <span>Delivery</span>
              <p>USA delivery by UPS express courier, shipping calculated at checkout</p>
              <hr />
              <span>ADDITIONAL INFORMATION</span>
              <p>100% insured shipping: A guarantee for your purchases</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;
