import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { InstantSearch, Configure, InfiniteHits } from "react-instantsearch-dom";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartReducer";
import CartSummary from "../../components/cart-summary/cart-summary";
import "./search-results.scss";

const { searchClient } = instantMeiliSearch(
  "https://search.emtechindustrialsupply.com",
  "nPbdxpjJiSZyWKpJsXx45JnGhHczGAyEO4jjzstAXX0"
);

const SearchResults = () => {
  const [maxPrice, setMaxPrice] = useState(10000);
  const [sort, setSort] = useState("default");
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [hitsPerPage, setHitsPerPage] = useState(6);
  const [lastAddedItem, setLastAddedItem] = useState(null);
  const [showCartSummary, setShowCartSummary] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState(null);

  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search).get("query") || "";

  const { data: brandsData, loading: brandsLoading } = useFetch(`/brands`);

  const handleBrandChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelectedBrands(
      isChecked
        ? [...selectedBrands, value]
        : selectedBrands.filter((item) => item !== value)
    );
  };

  const handleSortChange = (e) => {
    setSort(e.target.value);
  };

  const handleShowMore = () => {
    setHitsPerPage(hitsPerPage + 6);
  };

  const handleAddToCart = (item) => {
    const product = {
      id: item.id,
      title: item.title,
      price: item.price,
      img: item.img?.url,
      product_name: item.product_name,
      quantity: 1,
    };

    // Add the product to the cart using Redux
    dispatch(addToCart(product));

    // Update local state
    setLastAddedItem(product);

    // Reset showCartSummary to retrigger its appearance
    setShowCartSummary(false); // Hide CartSummary first
    setTimeout(() => setShowCartSummary(true), 0); // Show CartSummary again after a short delay
  };

  useEffect(() => {
    // Re-trigger the search whenever filters or sort options change
  }, [maxPrice, sort, selectedBrands, selectedCondition]);

  return (
    <div className="collection">
      <div className="title-container">
        <h1 className="title">Search Results: {query}</h1>
      </div>

      <div className="breadcrumbs">
        <Link className="text1" to="/">home</Link> / search-results: {query}
      </div>

      <div className="products">
        <div className="left">
          <div className="filterItem">
            <h2>Brand</h2>
            {brandsLoading ? (
              <p>Loading brands...</p>
            ) : (
              brandsData?.map((item) => (
                <div className="inputItem" key={item.id}>
                  <input
                    type="checkbox"
                    id={`brand-${item.id}`}
                    value={item.id}
                    onChange={handleBrandChange}
                  />
                  <label htmlFor={`brand-${item.id}`}>
                    <p>{item.attributes.title}</p>
                  </label>
                </div>
              ))
            )}
          </div>

          <div className="filterItem">
            <h2>Price</h2>
            <div className="inputItem">
              <span>0</span>
              <input
                type="range"
                min={0}
                max={10000}
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
              />
              <span>{maxPrice}</span>
            </div>
          </div>

          <div className="filterItem">
            <h2>Condition</h2>
            {/* Reset option */}
            <div className="inputItem">
              <input
                type="radio"
                id="all"
                name="condition"
                value=""
                onChange={() => setSelectedCondition(null)} // Reset condition
                checked={selectedCondition === null}
              />
              <label htmlFor="all">All</label>
            </div>

            {/* New condition */}
            <div className="inputItem">
              <input
                type="radio"
                id="New"
                name="condition"
                value="New"
                onChange={() => setSelectedCondition('New')}
                checked={selectedCondition === "New"}
              />
              <label htmlFor="New">New</label>
            </div>

            {/* Used condition */}
            <div className="inputItem">
              <input
                type="radio"
                id="Used"
                name="condition"
                value="Used"
                onChange={() => setSelectedCondition("Used")}
                checked={selectedCondition === "Used"}
              />
              <label htmlFor="Used">Used</label>
            </div>
          </div>

          <div className="filterItem">
            <h2>Sort By</h2>
            <select value={sort} onChange={handleSortChange}>
              <option value="default">Default</option>
              <option value="asc">Price: Low to High</option>
              <option value="desc">Price: High to Low</option>
            </select>
          </div>
        </div>

        <div className="right">
          <div className="search-results-container">
            <InstantSearch indexName="product" searchClient={searchClient}>
              <Configure
                hitsPerPage={hitsPerPage}
                query={query}
                filters={[
                  maxPrice < 10000 ? `price <= ${maxPrice}` : null, // Price filter
                  selectedCondition ? `condition = "${selectedCondition}"` : null, // Condition filter
                  selectedBrands.length > 0
                    ? `brand_id IN [${selectedBrands.map((id) => `"${id}"`).join(",")}]`
                    : null, // Brand filter
                ]
                  .filter(Boolean) // Remove null filters
                  .join(" AND ")} // Combine filters with "AND"
                sortBy={sort === "asc" ? "price:asc" : sort === "desc" ? "price:desc" : undefined}
              />
              <InfiniteHits
                hitComponent={(props) => (
                  <Hit hit={props.hit} onAddToCart={handleAddToCart} />
                )}
              />
            </InstantSearch>
          </div>

          {hitsPerPage > 6 && (
            <button className="show-more" onClick={handleShowMore}>
              Show More
            </button>
          )}
        </div>
      </div>

      {/* Cart Summary */}
      {showCartSummary && lastAddedItem && (
        <CartSummary
          item={lastAddedItem}
          onClose={() => setShowCartSummary(false)}
        />
      )}
    </div>
  );
};

const Hit = ({ hit, onAddToCart }) => {
  const imageUrl = `https://api.emtechindustrialsupply.com${hit.img?.url}`;

  const stockStatus = hit?.status; // Get the stock status

  // Determine the color based on stock status
  const stockStatusColor = stockStatus === 'in stock' ? 'green' : 'red';

  return (
    <li className="hits2" key={hit.id}>
      <a href={`/products/${hit.product_name}`} className="hit2-link">
        <img src={imageUrl} className="hit2-image" alt={hit.title} />
        <p className="hit2-title">{hit.title}</p>
      </a>
      <p className='sn'>SKU: {hit.sku}</p>
      <p className="price">${hit.price.toFixed(2)}</p>
      <p className='condition'>{hit.condition}</p>
       <p className='status' style={{ color: stockStatusColor }}>
        {stockStatus === 'in stock' ? 'In Stock' : 'Out of Stock'}
      </p>
      <button
        className="add-to-cart"
        onClick={(e) => {
          e.preventDefault();
          onAddToCart(hit);
        }}
      >
        Add to Cart
      </button>
    </li>
  );
};

export default SearchResults;
